
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100..900&display=swap');

/* COLOR GUIDE
USE THIS TO CHOOSE COLORS FOR NEW COMPONENTS.
In order to migrate from this document to pure react we need to update the antd version and use the configProvider.

--ant-primary-color: rgb(153, 102, 184);
    --ant-primary-color-disabled: #e7ddeb;
    --ant-primary-color-hover: #b08bc4;
    --ant-primary-color-active: #734991;
    --ant-primary-color-outline: rgba(153, 102, 184, 0.2);
    --ant-primary-color-deprecated-bg: #f4e9f7;
    --ant-primary-color-deprecated-border: #dad1de;
    --ant-primary-1: #f4e9f7;
    --ant-primary-2: #e7ddeb;
    --ant-primary-3: #dad1de;
    --ant-primary-4: #c8b6d1;
    --ant-primary-5: #b08bc4;
    --ant-primary-6: #9966b8;
    --ant-primary-7: #734991;
    --ant-primary-8: #51306b;
    --ant-primary-9: #311c45;
    --ant-primary-10: #150c1f;
    --ant-primary-color-deprecated-l-35: rgb(234, 224, 241);
    --ant-primary-color-deprecated-l-20: rgb(199, 172, 216);
    --ant-primary-color-deprecated-t-20: rgb(173, 133, 198);
    --ant-primary-color-deprecated-t-50: rgb(204, 179, 220);
    --ant-primary-color-deprecated-f-12: rgba(153, 102, 184, 0.12);
    --ant-primary-color-active-deprecated-f-30: rgba(244, 233, 247, 0.3);
    --ant-primary-color-active-deprecated-d-02: rgb(240, 225, 244);
    --ant-info-color: rgb(182, 0, 124);
    --ant-info-color-disabled: #e895c2;
    --ant-info-color-hover: #c21f89;
    --ant-info-color-active: #8f0066;
    --ant-info-color-outline: rgba(182, 0, 124, 0.2);
    --ant-info-color-deprecated-bg: #f5dce9;
    --ant-info-color-deprecated-border: #db69ac;

*/

* {
    font-family: 'M Plus 2' !important;
}

a {
  color: #1890ff !important;
}

/* Need to change Alert border to steel pink */
.ant-alert-info {
  border: 1px solid #b6007c !important;
}

/* Need to change Alert border to steel pink */
.ant-alert-warning {
  border: 1px solid #b6007c !important;
}

/* Plots and tables cards */

.ant-card-hoverable:hover {
  box-shadow: 0 1px 2px -2px rgba(153, 102, 184,.16), 0 3px 6px 0 rgba(153, 102, 184,.12), 0 5px 12px 4px rgba(153, 102, 184,.09) !important;
}

/* Tree Checkbox */

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: rgb(153, 102, 184) !important;
  border-color: rgb(153, 102, 184) !important;
}

.ant-tree-checkbox:hover .ant-tree-checkbox-inner{
  border-color: rgb(153, 102, 184) !important;
}

/* Selector on hover (e.g data upload modal) */

.ant-select-focused:not( .ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
 border-color: #b08bc4 !important; /* primary-hover */
 box-shadow: 0 0 0 2px rgba(153, 102, 184, 0.2) !important; /* primary-outline */
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #b08bc4 !important; /* primary-hover */
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f4e9f7 !important; /* primary-1 */
}

/* Number input on hover and focus*/

.ant-input-number:hover{
 border-color: #b08bc4 !important;
}

.ant-input-number-focused {
  border-color: #b08bc4 !important;
  box-shadow: 0 0 0 2px rgba(153, 102, 184, 0.2) !important;
}


/* Radio buttons */

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(153, 102, 184, 0.2) !important;
}

/* Default state */
.ant-radio-inner {
  border-color: rgb(153, 102, 184) !important;
  --antd-wave-shadow-color: rgb(153, 102, 184) !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: rgb(153, 102, 184) !important;
}

/* Hover state */
.ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #b08bc4 !important;
}

/* Focus state */
.ant-radio-focused .ant-radio-inner,
.ant-radio-wrapper:focus .ant-radio-inner,
.ant-radio-inner:focus {
  box-shadow: 0 0 0 2px rgba(153, 102, 184, 0.2) !important;
}


/* Selected menu and sider */

.ant-menu-item-selected{
  background-color: #9966b8 !important;
}

.ant-layout-sider-trigger{
    background-color: #11001b !important;
}


/* Button changes */

button {
    border-radius: 0.25rem !important;
}

button.ant-switch {
  border-radius: 100px !important;
}

a.ant-btn{
  color: #fff !important;
}

.ant-btn-text:hover {
  color: #fff !important;
}

.ant-spin-dot-item {
  background-color: #4c067e !important;
}
/* Gene table  */

.even-row .ant-table-cell {
    background-color: #E5E5F1 !important;
}

.ant-table-tbody > tr.ant-table-row-selected.even-row  > td {
  background: #E5E5F1 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fff !important;
}

.gene-table .ant-table-header .ant-table-cell{
   background-color: #C4BCDC !important;
}


/* Highlights */

.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #4c067e !important;
}
.ant-tabs-ink-bar{
    background: #4c067e !important;
}


/* Slider input */

/* Slider Handle Default State - The default look of the slider handle */
.ant-slider-handle {
  border-color: rgb(153, 102, 184) !important;
  background-color: #ffffff !important;

}

/* Slider Handle Focus State - When the handle is focused, typically via keyboard navigation */
.ant-slider-handle:focus {
  border-color: rgb(153, 102, 184) !important;
  background-color: #ffffff !important;
  --antd-wave-shadow-color: rgb(153, 102, 184) !important;
  box-shadow: 0 0 0 5px rgba(153, 102, 184, 0.12) !important;
}

/* Slider Handle Dragging State - While the handle is being dragged */
.ant-slider-handle-dragging {
  border-color: rgb(153, 102, 184) !important;
  background-color: #ffffff !important;
  --antd-wave-shadow-color: rgb(153, 102, 184) !important;
  outline: none !important;
  box-shadow: 0 0 0 5px rgb(153, 102, 184) !important;
}

/* Slider Handle Hover State - When the mouse hovers over the handle */
.ant-slider-handle:hover {
  border-color: #b08bc4 !important;
  background-color: #ffffff !important;
  --antd-wave-shadow-color: rgb(153, 102, 184) !important;
  box-shadow: 0 0 0 5px rgba(153, 102, 184, 0.12) !important;
}

/* Slider Handle Focus and Hover State - Specific for when the handle is focused and hovered over */
.ant-slider-handle:focus:hover {
  background-color: #ffffff !important;
  border-color: rgb(153, 102, 184) !important;
  --antd-wave-shadow-color: rgb(153, 102, 184) !important;
  box-shadow: 0 0 0 5px rgba(153, 102, 184, 0.12) !important;
}

.ant-slider-track, .ant-select-tree-node-selected  {
  background-color: #dad1de !important;
}

.ant-slider:hover .ant-slider-track{
  background-color: #b08bc4 !important;
}

.ant-slider-dot-active {
  border-color: rgb(204, 179, 220) !important;
}
